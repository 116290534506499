:root {
  --theme-primary: #9f8ffd;
  --theme-secondary: #111729;
  --theme-tertiary: #212836;
  --theme-gradient: #2a2b76e0;
  --text-primary: rgb(209, 213, 219);
  --text-accent: #3ee7ab;
  --section: #131727;
  --footer: #212836;
}

html {
  font-size: 18px;
}

body {
  background: url('../assets/images/socks_background.jpeg') no-repeat top center;
  background-repeat: no-repeat;
  background-size: 150%;
  background-color: var(--theme-secondary);
  color: var(--text-primary);
  font-family: "Inter var", Inter, ui-sans-serif, system-ui, -apple-system,
    "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, system-ui,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-weight: 400;
}

hr {
  border-top: 1px solid var(--text-primary);
  width: 90%;
}


footer {
  background: var(--theme-tertiary);
}

section {
  position: relative;
}

a {
  color: var(--theme-primary);
  font-weight: 700;
}

.logo {
  background: url(https://www.basicneeds.help/images/logo_black_wo_text_svg.svg)
    no-repeat center;
  filter: invert(100%);
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 0.25rem;
}

.logo--header {
  height: 32px;
  width: 32px;
}

.person-image {
  width: 300px;
  height: 300px;
  border-radius: 4px;
  display: inline-block;
  object-fit: cover;
}

.person-image--1 {
  background: url('../assets/images/chelsea_profile.jpeg') no-repeat top center;
  background-size: cover;
}

.person-image--2 {
  background: url('../assets/images/pandora_profile.jpeg') no-repeat top center;
  background-size: cover;
}

.person-image--3 {
  background: url('../assets/images/ryan_profile.jpeg') no-repeat top center;
  background-size: cover;
}
.h5,
.font-weight-bold {
  color: white;
}

.h1,
h2,
.text-accent {
  color: var(--text-accent);
  font-weight: 700;
}

.btn-primary {
  background: var(--theme-primary);
  border: var(--theme-primary);
  width: 200px;
  height: 50px;
}

.btn-secondary {
  background: #00000054;
}

.section-2 {
  background: var(--theme-secondary);
}

.mint-card {
  background: var(--theme-secondary);
  border: var(--theme-secondary);
  width: 60%;
  max-width: 580px;
  min-width: 320px;
}

.rotated-square {
  height: 40px;
  width: 40px;
  position: absolute;
  transform: rotate(45deg);
  top: -18px;
  left: calc(50% - 20px);
  z-index: 1;
  background: var(--theme-secondary);
}

.wrap {
  min-height: 100%;
}

main {
  overflow: auto;
  padding-bottom: 100px; /* this needs to be bigger than footer height*/
}

footer {
  position: relative;
  margin-top: -100px; /* negative value of footer height */
  height: 100px;
  clear: both;
  padding-top: 20px;
}


